.new-page-container {
    display: flex;

  }

  
  .other-content {
    padding: 20px;
    /* border: 1px solid #ccc; */
    /* background-color: red; */
    width: 100%;
    margin-left: 0;
    /* margin-left: 30vh; */
    
  }

  .content {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  
  .dashboard-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    
  }
  
  .dashboard-box {
    flex: 1;
    padding: 50px;
    margin: 20px;
    text-align: center;
    border-radius: 5px;
  }
  
  .dashboard-box h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .dashboard-box h3 {
    font-size: 18px;
    color: #333;
  }
  
  .dashboard-color1 { background-color: #ff9800; }
  .dashboard-color2 { background-color: #4caf50; }
  .dashboard-color3 { background-color: #36e4f4; }
  .dashboard-color4 { background-color: #1faef1; }
  /* .dashboard-color4 { background-color: #4caf50; } */
  
  .dashboard-charts {
    display: flex;
    justify-content: space-between;
  }
  .chart {
    flex: 1;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .chart h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .chart img {
    max-width: 100%;
    border-radius: 5px;
  }
  

  .logout-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: none;
    justify-content: center;
    align-items: center;
  }
  
  .logout-popup.open {
    display: flex; /* Display the popup when isOpen is true */
  }
  
  .logout-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .logout-content h2 {
    margin-top: 0;
  }
  
  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .buttons button {
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .buttons button:first-child {
    background-color: #ff5555; /* Red color for Logout button */
    color: white;
  }
  
  .buttons button:last-child {
    background-color: #874487;  /* Light gray color for Cancel button */
  }
  