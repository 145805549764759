/* Loader.css */

/* Center the loader and maintain its current styling */
.loading {
  position: fixed;
  top: 0;
  left: 15%; /* Margin from the left */
  width: calc(100% - 20px); /* Adjust width to keep the left margin */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999; /* Ensure it is above other elements */
  backdrop-filter: blur(5px); /* Apply blur effect */
  pointer-events: none; /* Prevent interactions with the background */
}
.loader {
  display: block;
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  /* Centering logic */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader animation */
.loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
}

@keyframes moving {
  50% {
      width: 100%;
  }
  100% {
      width: 0;
      right: 0;
      left: unset;
  }
}

/* Overlay for background blur with left margin */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 20px; /* Margin on the left */
  width: calc(100% - 20px); /* Adjust width to accommodate left margin */
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(5px); /* Apply blur effect */
}
