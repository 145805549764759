/* Invoice container */
.invoice-container {
  font-family: 'Times New Roman', Times, serif;
  /* max-width: 800px; */
  /* margin: 0 auto; */
  padding: 20px;
  /* border: 1px solid #ccc; */
  background-color: #fff;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

/* Invoice header */
.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-header div {
  width: 30%;
}

/* Address section */
.invoice-address {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
}

/* Invoice items table */
.invoice-items table {
  width: 95%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 10px; 
  margin-left: 1vh;
  align-items: start;
}

.invoice-items th,
.invoice-items td {
  border: 1px solid #ccc;
  padding: 10px;
  /* text-align: center; */
}

/* Invoice summary */
.invoice-summary {
  text-align: right;
  margin-right: 10%;
  font-weight: bold;
  
}
.invoice-summary p,
.invoice-summary h2 {
    margin: 0.4em 0; /* Set smaller margins */
    padding: 0; /* Remove default padding for paragraphs and h2 */
}

.invoice-summary p {
  /* margin-top: 10px; */
  /* color: rgb(244, 51, 51); */
  font-size: 12px;
}
.invoice-summary h2 {
  /* margin-top: 1px; */
  /* background-color: red; */
  

  color: rgb(14, 14, 14);
  font-size: 14px;
}
/* Styling for alternating table rows */
.invoice-items tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Styling for table header and cells */
.invoice-items th,
.invoice-items td {
  text-align: center;
  background-color: #fff; /* Set background color to white */
}

/* Styling for table header */
.invoice-items th {
  background-color: #00b5bc;
  text-align: center;
  /* color: #fff; */
}



.payment-items {
  font-size: 12px;
  padding: 10px;
  background-color: transparent;
  width: 30%;
}

.payments-header,
.payment-row {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
}

.payments-header {
  font-weight: bold;
  border-bottom: 1px dotted #000;
  margin-bottom: 5px;
}

.payment-row {
  padding: 5px 0;
}

.payment-row div,
.payments-header div {
  /* flex: 1; */
  text-align: left;
  justify-content: space-between;
}

.payment-row div:nth-child(4),
.payments-header div:nth-child(4) {
  text-align: right;
}

.no-data {
  text-align: center;
  margin-top: 5px;
}


.total-paid {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-weight: bold;
}






/* Styling for invoice title */
.invoice-title {
  text-align: center;
  margin-bottom: 20px;
}

.invoice-title h2 {
  font-size: 24px; /* Larger font size for the title */
  /* color:rgb(244, 51, 51); */
  color:rgb(6, 5, 5);
  padding-top: 23px;
}

.customer-details {
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  align-items: flex-start; /* Align items to the left */
  margin-bottom: 20px;
}

.customer-detail {
  width: auto; /* Allow the elements to adjust their widths */
  margin-bottom: 10px; /* Add space between the elements */
}

.address-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.address-detail {
  width: 48%;
}

.customer-detail h3{
  margin-bottom: 5px;
  font-size: 13px; 
  font-weight: bold;
}
.address-detail h3 {
  margin-bottom: 5px;
  font-size: 16px; /* Adjust font size for the headings */
  font-weight: bold; /* Make headings bold */
}

.company-details {
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
}

.company-left {
  flex: 1;
  align-items: start;
  /* margin-left: -20vh; */
  justify-items: start;
  text-align: left; 
  
}

.company-center h5{
  flex: 1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.company-right {
  flex: 1;
  text-align: right;
  margin-right: 8%;
}

/* .company-left h5 {
  margin-bottom: 5px;
} */

/* .company-left p,
.company-right p {
  margin: 3px 0;
  font-size: 12px;
} */

.line-between {
  position: relative;
}

.line-between::before,
.line-between::after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 2px solid black; /* Change border color to red */
  width: 90%;
}

.line-between::before {
  right: 100%;
  margin-right: 10px;
}

.line-between::after {
  left: 100%;
  margin-left: 10px;
}

.print-button {
  margin-top: 20px;
}

.print-button button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;

}




.print-button button:hover {
  background-color: darkblue;
}

.thanks {
  display: none;
}

@media print {
  .thanks:last-of-type {
    display: block;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    margin-left: 0;
    padding: 10px;
  } 
}
.gstin-container {
  display: inline-block; /* To ensure it appears inline */
}

.gstin-heading {
  display: inline-block;
  font-size: inherit; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  /* Add any other styling as per your requirement */
  
}
.gstin-number {
  margin-left: 10vh;
  }
.gstin-heading,
.gstin-number {
    margin: 3px 0;
    font-size: 12px;
    font-family: Times new Roman;
  }  
.gstin-space {
  margin-right: 2px; /* Adjust the space as needed */
}

.notes p{
  flex: 1;
  text-align: start;
  margin: 0;
  font-size: 12px;
}

.notes h5 {
  flex: 1;
  text-align: start;
  margin: 0;
  font-size: 14px;
  font-weight: bold; /* Add font weight to make text bold */
}

/* Flex container for header */
.header-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* Company logo on the left */
.company-logo {
  flex: 0 0 auto;
  margin-right: 78px;
}
  
/* Company information on the right */
.company-info1 {
  flex: 1;
  text-align: left;
}

.company-name {
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}

/* Adjust the styles for the gstin heading and number */
.gstin-h, .gstin-n {
  display: block;
  margin: 3px 0;
  font-size: 11px;
  font-family: 'Times New Roman', Times, serif;
}

.gstin-heading1 {
  display: inline-block;
  font-size: inherit; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  /* Add any other styling as per your requirement */
  
}
.gstin-number1 {
  margin-left: 10vh;
  }
.gstin-heading1,
.gstin-number1 {
    margin: 3px 0;
    font-size: 18px;
    font-family: Times new Roman;
    font-weight: bold;
  }  

  .gstin-heading2 {
    display: inline-block;
    font-size: 12px; /* Adjust font size as needed */
   
    /* Add any other styling as per your requirement */
    
  }
/* Additional styling as needed */
.gstin-heading4 {
  display: inline-block;
  font-size: inherit; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  /* Add any other styling as per your requirement */
  
}
.gstin-number4 {
  margin-left: 10vh;
  }
.gstin-heading4,
.gstin-number4 {
    margin: 3px 0;
    font-size: 13px;
    font-family: Times new Roman;
  }  
  .signature-container {
    display: flex; /* Enable flexbox for alignment */
    justify-content: flex-end; /* Align content to the right */
    align-items: center; 
    margin-right: 150px/* Align content vertically on the same line */
  }
  


  .company-logo1 {
    /* Existing styles */
    z-index: 1;
  }
  