/* CommonPage.css */

.app {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color:#01b4b9;
  color: #fff; /* Font color */
  padding: 20px;
  justify-content: space-between;
  min-height: 100vh;
  max-height: 800vh;
  overflow: hidden; /* Hide overflow content */
}

.menu-icon {
  margin: 0 10px; /* Adjust margin as needed */
}

.menu-item {
  cursor: pointer;
  padding: 10px 0;
}

.menu-item:hover {
  color: #f23535; /* Red color */
}

.submenu {
  margin-top: 10px;
}

.submenu-item {
  cursor: pointer;
  padding: 10px 0 10px 20px;
}

.submenu-item:hover {
  color: #f23535;
}

.selected-menu-item,
.selected-submenu-item {
  font-weight: bold;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f4;
  color: #000; /* Font color */
}

/* Modal Styles */

/* Modal Content */
.modal-content-logout {
  background-color: #fefefe;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  border-radius: 8px;
  margin: 10vh auto; /* Center modal vertically */
  padding: 20px;
}

/* Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Logout Content */
.logout-content {
  text-align: center;
}

/* Logout Buttons */
.logout-buttons {
  margin-top: 20px;
}

.logoutt,
.cancel {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logoutt {
  background-color: #f44336; /* Red */
  color: white;
}

.cancel {
  background-color: #ddd; /* Light gray */
  color: black;
}

/* Hover Effects */
.logoutt:hover,
.cancel:hover {
  opacity: 0.8;
}

/* Add CSS to show the modal when it's open */
.logoutModal {
  display: none; /* Hide the modal by default */
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content-logout {
  width: 80%;
  background-color: transparent;  
  height: 40vh;
}

/* Add styles to center the modal content */
.logout-content {
  text-align: center;
}

.logout-buttons {
  margin-top: 20px;
}

/* Ensure that the modal is displayed when it's open */
.logoutModal.show {
  display: block;
}
