.navbar {

    /* position: relative; */
    margin-left: 5cqmin;
    margin-right: 0.3vh;
    overflow: hidden;
    padding: 1vh;
    /* border-radius: 1vh; */
    border: 3px solid rgb(185, 176, 176);
  /* z-index: -1; */
  }
  
  
  .item{
    margin-left: 10vh;
    padding-left: 30vh;
  }
  .head{
    text-align: center;
    background-color: antiquewhite;
    font-family: Arial;
    font-size: 24px;
  }
  .invoice-item{
    background-color: red;
  }
  
  .invoice-heading {
    background-color: aqua;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 100vh;
  }
  
  .table-container {
    flex-grow: 1; /* Allow the table container to grow within the parent */
    max-height: 60vh; /* Define a height limit for the table container */
    overflow-y: auto; /* Make the container scrollable */
    margin-top: 20px;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-table th,
  .invoice-table td {
    padding: 8px;
    text-align: left;
    
  }
  
  .invoice-table th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0; /* Sticky header */
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-number,
  .prev-next,
  .ellipsis {
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .page-number.active {
    background-color: #007bff;
    color: white;
    border: none;
  }
  
  .prev-next,
  .ellipsis {
    background-color: #e9ecef;
  }
  .green-button {
    background-color: green;
    color: white; /* Optional: to make the text color white for better contrast */
    border: none; /* Optional: to remove the default button border */
    padding: 8px 20px; /* Optional: to add some padding for better appearance */
    border-radius: 5px; /* Optional: to make the corners rounded */
    cursor: pointer; /* Optional: to change the cursor on hover */
    }
    
    .green-button:hover {
    background-color: darkgreen; /* Optional: to change the background color on hover */
    }
    
  .generated-button {
    background-color: #d4edda; /* Light green color */
    color: #155724; /* Dark green text color */
    border: none;
    /* padding: 0.5rem 1rem; */
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .generated-button:hover {
    background-color: #c3e6cb; /* Lighter shade of green color on hover */
  }
  
  .generate-button {
    background-color: #007bff; /* Blue color */
    color: #ffffff; /* White text color */
    border: none;
    /* padding: 0.5rem 1rem; */
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .generate-button:hover {
    background-color: #0056b3; /* Darker blue color on hover */
  }
  .pagination {
    float: right;
    margin-right: 1vh;
    margin-top: 0.5vh;
  }
  .pagination button {
    padding: 1px 4px; /* Adjust padding to make buttons smaller */
    font-size: 12px; /* Adjust font size to make buttons smaller */
    margin-right: 5px; /* Add margin between buttons */
    border-radius: 1vh;
  }
  
  /* Style for the modal */
  .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
  border: 2px solid #0c0a0a;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); 
  }
  
  /* Close Button */
  .close {
  color: #d31313;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-left: 95%;
  }
  
  
  
  .close:hover,
  .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  }
  
  /* Modal Content Styling */
  .pop-box {
  text-align: center;
  border: none;
  }
  
  .pop-box h2 {
  color: #333;
  font-size: 24px;
  /* margin-bottom: 20px; */
  }
  
  .pop-box p {
  color: #666;
  /* font-size: 16px; */
  line-height: 1.6;
  /* margin-bottom: 20px; */
  }
  .close-button {
  position: absolute;
  bottom: 0;
  left: 59%;
  transform: translateX(-50%);
  background-color: rgb(234, 148, 44);
  color: white;
  border: none;
  padding: 10px 7px;
  width: 15vh;
  cursor: pointer;
  margin-bottom: 3.4vh;
  font-size: 8px; /* Adjust font size as needed */
  font-weight: bold; /* Add bold font weight for emphasis */
  text-transform: uppercase; /* Convert text to uppercase */
  letter-spacing: 1px;
  }
  
  
  
  
  .close-button:hover {
  background-color: darkblue;
  }
  
  
  .pagination {
  /* margin-top: 20px; */
  text-align: center;
  }
  
  .page-number {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  }
  
  .active {
  background-color: #007bff;
  color: #fff;
  }
  
  .prev-next {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  }
  
  .ellipsis {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: transparent;
  color: #333;
  cursor: default;
  }
  
  .master-details-container {
  display: flex;
  justify-content: space-between;
  }
  
  .left-side {
  width: 30%;
  padding: 20px;
  /* border: 1px solid #ccc; */
  }
  
  .right-side {
  /* width: 60%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  .right-side {
  display: flex;
  align-items: center;
  }
  
  /* .search-bar {
  display: flex;
  margin-right: 1vh;
  } */
  
  .buttons input {
  margin-right: 1vh;
  margin-left: 30vh;
  border: 1px solid #ccc; /* Add border */
  border-radius: 5px; /* Apply border radius */
  
  }
  
  .green-button {
  background-color: green;
  color: white; /* Optional: to make the text color white for better contrast */
  border: none; /* Optional: to remove the default button border */
  padding: 8px 20px; /* Optional: to add some padding for better appearance */
  border-radius: 5px; /* Optional: to make the corners rounded */
  cursor: pointer; /* Optional: to change the cursor on hover */
  }
  
  .green-button:hover {
  background-color: darkgreen; /* Optional: to change the background color on hover */
  }
  
  
  .buttons button {
  margin-right: 10px;
  /* padding: 10px 20px;  */
  border: none; /* Remove default button border */
  border-radius: 5px; /* Apply border radius */
  background-color: #874487;
  color: white; /* White text color */
  cursor: pointer; /* Add cursor pointer */
  }
  
  /* .buttons button:last-child{
  background-color: red;
  } */
  
  .buttons button:hover {
  background-color: #45a049; /* Darker green color on hover */
  }
  .container {
  display: flex;
  justify-content: center;
  }
  
  .box-product {
  width: 30px; /* Adjust width as needed */
  height: 15px; /* Adjust height as needed */
  background-color: #6e5898; /* Background color */
  margin-top: 1vh; /* Top margin */
  margin-right: 5vh; /* Right margin */
  margin-left: 2vh; /* Left margin */
  }
  
  .box-service {
  width: 30px; /* Adjust width as needed */
  height: 15px; /* Adjust height as needed */
  background-color: #4bd6ae; /* Background color */
  margin-top: 1vh; /* Top margin */
  margin-right: 5vh; /* Right margin */
  margin-left: 2vh; /* Left margin */
  }
  
  /* .box-with-border {
  width: 100px; 
   height: 100px;  
  } */
  
  .product-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  }
  
  .filter-dropdown {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  }
  
  
  .modal-content-center {
  background-color: #fefefe;
  margin: 10% auto; /* 15% from the top and centered */
  margin-left: 40vh;
  /* padding: 18px; */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
  border: 2px solid #0c0a0a;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.2);  */
  }
  .close-button-center {
  color: #ffffff;
  float: right;
  font-size: 22px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  margin-right: 3vh;
  /* background-color: #dcd4d4; */
  }
  
  .close-button-center:hover,
  .close-button-center:focus {
  color: rgb(227, 10, 10);
  text-decoration: none;
  cursor: pointer;
  }
  
  
  
  
  .add-center-form-container {
  width: 100%; 
  /* margin: 0 auto; */
  /* margin-left: -3.3vh; */
  
  /* height: 2vh; */
  }
  
  .form-heading {
  text-align: start;
  width: 100%;
  margin-bottom: 20px;
  background-color: #874487;
  height: 10vh;
  font-family: Arial;
  font-size: 30px;
  color: white;
  /* margin-left: 5vh; */
  /* margin-top: -1vh; */
  }
  
  
  
  .form-heading-tax {
  text-align: start;
  width: 100%;
  margin-bottom: 20px;
  height: 10vh;
  font-family: Arial;
  font-size: 30px;
  color: white;
  margin-left: 5vh;
  /* margin-top: -1vh; */
  }
  
  .form-inline {
  display: flex;
  flex-wrap: wrap;
  }
  
  .form-input {
  width: calc(100% - 50px); /* Adjust width as needed */
  padding: 10px;
  margin-bottom: 10px; /* Reduced margin */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10vh;
  }
  
  .add-center-form-container h2 {
  text-align: left;
  color: inherit;
  }
  
  
  .form-group label {
  display: block; 
  text-align: center; 
  }
  
  
  .submit-button {
  width: 10%;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #874487;
  color: #fff;
  font-size: 1em;
  cursor: pointer;  
  margin-left: 165vh;
  margin-bottom: 3vh;
  }
  
  .submit-button:hover {
  background-color: #0056b3;
  }
  
  .submit-button:focus {
  outline: none;
  }
  
  
  .icon-container {
  display: flex;
  }
  
  .icon-container > * {
  margin-right: 10px;
  }
  
  .blue {
  color: blue;
  cursor: pointer;
  }
  
  .green {
  color: green;
  cursor: pointer;
  
  }
  
  .red {
  color: red;
  cursor: pointer;
  
  }
  
  .icon {
  font-size: 20px; /* Adjust the size as needed */
  }
  
  
  .modal-content-logout {
  margin: 10% auto;
  margin-left: 60vh;
  border: 1px solid #888;
  width: 50%;
  border-radius: 10px;
  border: 2px solid #0c0a0a;
  }
  
  
  
  /* Modal */
  
  /* Modal */
  /* .modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  } */
  
  .modal-content-center {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 10px;
  border: 1px solid #888;
  width: 85%;
  border-radius: 10px;
  position: relative;
  }
  
  .close-button-center {
  position: absolute;
  top: 30px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
  }
  
  .add-center-form-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  }
  
  .form-heading {
  margin-bottom: 5px;
  text-align: center;
  }
  
  .form-heading-tax {
  font-size: 16px;
  font-weight: bold;
  }
  
  .form-inline {
  display: flex;
  flex-direction: column;
  }
  
  .form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
  }
  
  .form-row .form-group {
  width: calc(33.33% - 10px); 
  }
  
  .form-row label {
  display: block;
  margin-bottom: 5px;
  }
  
  .form-input {
  
  width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  }
  
  /* .centers-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  }
  
  .center-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  }
  
  .center-checkbox.checked {
  background-color: #007bff;
  color: white;
  }
  
  .center-checkbox input[type="checkbox"] {
  margin-right: 10px;
  }
  
  .center-checkbox label {
  margin: 0;
  cursor: pointer;
  }
  
  .error-message {
  color: red;
  font-size: 14px;
  } */
  
  .submit-button {
  width: 10vh;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  }
  
  
  
  
  
  
  .centers-container {
  max-height: 200px; 
  margin-left: 5vh;
  overflow-y: auto; 
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .center-checkbox {
  
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #b4b3b3;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s ease;
  }
  
  .center-checkbox.checked {
  background-color: #007bff;
  }
  
  .center-checkbox input[type="checkbox"] {
  margin-right: 10px;
  }
  
  .center-checkbox label {
  margin: 0;
  cursor: pointer;
  width: 100%;
  }